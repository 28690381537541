import axios, { AxiosResponse } from 'axios';
import CryptoJS from 'crypto-js';

const dev = true;

const location = window.location.hostname === 'localhost' ? `http://localhost:1878` : window.location.origin 

declare global {
  interface Window {
    userIp: string;
  }
}

setInterval(async() => {

  if(window.location.hostname===`localhost`) return;
  
  const ip_data = await axios({
    method: "GET",
    url:`${location}/api/info`,
  })

  window.userIp = ip_data?.data.trim()
}, 1000 * 30);

const lot = ({ method = 'GET', id=null, collection=null, query, data=null, headers=null, jwt = null }: any): Promise<any> => {
  return new Promise(async (resolve: (value: any) => void, reject: (reason?: any) => void) => {

    const encrypt = encodeURIComponent(CryptoJS.AES.encrypt(query, 'preCryptoAiCarSeller').toString());

    if(typeof window.userIp === 'undefined'){
          
      if(window.location.hostname===`localhost`){ 
      
        window.userIp = '127.0.0.1'

      }else{
        const ip_data = await axios({
          method: "GET",
          url:`${location}/api/info`,
        })

        window.userIp = ip_data?.data.trim()
      }
    }

    const ip = window.userIp;
    
    const timestamp = Date.now();
    const public_hash_key = 'preHashAiCarSeller';

    const hash = CryptoJS.SHA256(ip+timestamp+public_hash_key).toString()

    let url = id ? `${location}/api/${collection}/${id}?timestamp=${timestamp}&hash=${hash}` : `${location}/api/${collection}?timestamp=${timestamp}&hash=${hash}`
    url = query ? `${url}&query=${encrypt}` : url
    
    if(dev) url =  id ? `${location}/api/${collection}/${id}` : `${location}/api/${collection}`;
    if(dev) url = query ? `${url}?${query}` : url;


    // Базовые заголовки
    const defaultHeaders = {
      'accept': 'application/json',
      'cache-control': 'no-cache',
      'Content-Type': 'application/json',
    };

    // Заголовок авторизации, если jwt передан
    const authHeaders = jwt ? { Authorization: `Bearer ${jwt}` } : {};

    // Объединяем все заголовки: если headers переданы, они приоритетнее, затем базовые и auth
    const finalHeaders = headers
      ? { ...defaultHeaders, ...authHeaders, ...headers }
      : { ...defaultHeaders, ...authHeaders };

      
    axios({
      method,
      url,
      timeout: 1000 * 60 * 2,
      headers: finalHeaders,
      data: data,
    })
      .then((response: AxiosResponse) => {
        
        if (response.status === 200) {

          if(response?.data){

            if(dev)return resolve(response.data);
            
            const bytes  = CryptoJS.AES.decrypt(decodeURIComponent(response?.data?.data), `preCryptoAiCarSeller+${ip}`);
            const decrypt = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            //console.log(`decrypt`);console.log(decrypt);

            const data =  decrypt // response.data;

            return resolve(data);
          }
          return reject({});
        } else {
          return reject(`Request failed with status ${response.status}`);
        }
      })
      .catch((error: any) => {
        console.error(error);
        return reject(error);
      });
  });
};

export default lot;
export { lot };
