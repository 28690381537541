import { atom, selector } from "recoil"

export const isLoadedAppStateAtom = atom({
  key: "isLoadedAppStateAtom",
  default: false,
})

export const isLoadedAppStateSelector = selector({
  key: "isLoadedAppStateSelector",
  get: ({ get }) => {
    const isLoadedApp = get(isLoadedAppStateAtom)
    return isLoadedApp
  }
})

